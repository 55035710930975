@import "google_oauth_button.css";
@import "bootstrap_util.css";

:root {
  --darkmode-secondary: rgba(255, 255, 255, 0.7);
  --lightmode-secondary: rgba(0, 0, 0, 0.6);
  --lightmode-divider: rgba(0, 0, 0, 0.12);
  --darkmode-divider: rgba(255, 255, 255, 0.12);
  --icon-gray: rgba(0, 0, 0, 0.54);
  --darkmode-bg-color: rgb(66, 66, 66);
}

.text-align-center {
  text-align: center;
}

.hidden {
  display: none !important;
}

body.darkmode input::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.light-bottom-border {
  border-bottom: 1px solid rgba(108, 117, 125, 0.25);
}

body.darkmode .next-button {
  border-color: var(--darkmode-secondary);
  color: var(--darkmode-secondary);
}

body.lightmode .next-button {
  border-color: var(--lightmode-secondary);
  color: var(--lightmode-secondary);
}

body.darkmode .next-top-border {
  border-top: 1px solid var(--darkmode-divider);
}

body.lightmode .next-top-border {
  border-top: 1px solid var(--lightmode-divider);
}

body.darkmode .next-bottom-border {
  border-bottom: 1px solid var(--darkmode-divider);
}

body.lightmode .next-bottom-border {
  border-bottom: 1px solid var(--lightmode-divider);
}

.short {
  font-size: 14px;
}

.muhtable {
  width: 100%;
}

.muhtable th {
  font-weight: 500;
}

.muhtable th,
.muhtable td {
  padding: 2px;
  text-align: center;
}

.piggy-span {
  width: 25px;
}

body.darkmode .piggy-span path {
  fill: white;
}

body.lightmode .piggy-span path {
  fill: var(--icon-gray);
}

body.lightmode .file-cover-btn {
  background: white;
}

body.darkmode .file-cover-btn {
  background: var(--darkmode-bg-color);
}

button.red-btn {
  color: #ff5722 !important;
  border-color: #ff5722 !important;
}

button.green-btn {
  color: #cddc39 !important;
  border-color: #cddc39 !important;
}

button.blue-btn {
  color: #2979ff !important;
  border-color: #2979ff !important;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.ft12 {
  font-size: 12px;
}

.center-all {
  display: flex;
  justify-content: center;
  align-items: center;
}
